<template>
  <div>
    <div class="addtask task-detail-modal">
      <b-modal
        id="UploadManual"
        v-model="isVisible"
        cancel-variant="outline-secondary"
        centered
        size="lg"
      >
        <template #modal-title>
          <h3 class="sprint-from-title sprint-bottom ml-0 mb-1 pb-50">
            Upload User Manual
          </h3>
        </template>
        <div>
          <b-row class="addtask-content">
            <b-col>
              <vue-dropzone
                id="dropzone"
                ref="myVueDropzone"
                v-model="mediaFile"
                :options="dropzoneOptions"
                @vdropzone-success="handleFileAdding"
                @vdropzone-removed-file="removeFile"
                @vdropzone-error="handleFileError"
              />
              <small class="text-danger">{{ FileErrorMsg }}</small>
            </b-col>
          </b-row>
        </div>
        <template #modal-footer>
          <b-button
            class="Upload-btn"
            :disabled="loader"
            @click="validationForm()"
          >
            Upload
          </b-button>
          <b-button
            class="cancel-btn"
            @click="$bvModal.hide(id)"
          >Cancel</b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    vueDropzone: vue2Dropzone,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: 'UploadManual',
    },
    modalID: {
      type: String,
      required: false,
      default: null,
    },
    attachment: {
      type: [String, Array],
      required: false,
      default: () => ([]),
    },
  },
  data() {
    return {
      loader: false,
      media_name: null,
      pdfFile: [],
      task_attachments: [],
      remove_attachments: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/task/image-store`,
        maxFiles: 1,
        uploadMultiple: false,
        parallelUploads: 100,
        // thumbnailWidth: 150,
        maxFilesize: 30,
        addRemoveLinks: true,
        addViewLinks: true,
        acceptedFiles: '.pdf,.ppt,.pptx',
        dictDefaultMessage:
          "<b class='drop-title'>Drag & drop files</b> <br /> Supported formates: PDF, PPT <br /> ",
      },
      isVisible: false,
      mediaFile: null,
      FileErrorMsg: null,
      errorFileList: [],
      isManuallyAddFile: false,
    }
  },
  watch: {
    isVisible(val) {
      if (val) {
        if (Object.values(this.attachment).length) {
          this.isManuallyAddFile = true
          this.file = this.attachment
          const file = {
            size: 150,
            name: `${this.attachment.name}`,
            type: `${this.attachment.type}`,
            id: `${this.attachment.id}`,
          }
          if (!this.actionTypeCheck) {
            this.$refs.myVueDropzone.manuallyAddFile(
              file,
              `${process.env.VUE_APP_API_IMAGE_PATH}/${this.attachment.url}`,
            )
          }
        }
      } else {
        this.file = []
        this.remove_attachments = []
        this.FileErrorMsg = null
        this.$emit('clearAttachment')
      }
    },
  },
  updated() {
    //
  },
  methods: {
    handleFileAdding(file) {
      if (!file.manuallyAdded == true) {
        if (file.status == 'success') {
          this.FileErrorMsg = null
          const fileName = file.name
          this.pdfFile.push(file)
          this.pdfFile.forEach(value => {
            const fileReader = new FileReader()
            let base64
            fileReader.onload = fileLoadedEvent => {
              base64 = fileLoadedEvent.target.result
              value.dataURL = base64
            }
            fileReader.readAsDataURL(value)
            //  Checks every second for the dataURL.
            var checkIfReady = setInterval(() => {
              // If there is it logs it.
              if (fileReader.result) {
                // USE the DATAURL
                clearInterval(checkIfReady)
              }
              // Stops checking after 10 seconds.
              setTimeout(() => {
                clearInterval(checkIfReady)
              }, 1000)
            }, 1000)
          })
        }
      }
    },

    // user for validation only
    handleFileError(file, message) {
      this.FileErrorMsg = message
      this.errorFileList.push(file)
    },

    removeFile(file) {
      if (file.id) {
        this.isManuallyAddFile = false
        this.remove_attachments.push(file.id)
        const task_attachmentsIndex = this.task_attachments.findIndex(
          x => x.id === file.id,
        )
        this.task_attachments.splice(task_attachmentsIndex, 1)
      } else {
        // user for validation only
        const ErrorFileIndex = this.errorFileList.findIndex(
          x => x.upload.uuid === file.upload.uuid,
        )
        this.errorFileList.splice(ErrorFileIndex, 1)

        this.FileErrorMsg = this.errorFileList && this.errorFileList.length
          ? this.FileErrorMsg
          : null
      }
    },

    /**
     * check the form validation
     * @returns if success true then call saveClientDtl Method
     */
    validationForm() {
      if (this.$refs.myVueDropzone.getAcceptedFiles().length == 0 && this.isManuallyAddFile == false) {
        this.FileErrorMsg = 'No Any Attachment Added !'
      } else if (this.errorFileList.length == 0) {
        this.uploadMediaFile()
      }
    },

    /** uplload media file */
    async uploadMediaFile() {
      this.loader = true
      const formData = new FormData()
      if (this.pdfFile && this.pdfFile.length) {
        this.pdfFile.forEach((element, index) => {
          formData.append(`files[${index}][file]`, element)
          formData.append(`files[${index}][name]`, element.name)
        })
      }
      if (this.remove_attachments && this.remove_attachments.length) {
        this.remove_attachments.forEach((element, index) => {
          formData.append('remove_file_ids[]', element)
        })
      }
      formData.append('_method', 'PUT')

      const response = await this.getHTTPPostResponse(
        `admin/master/user-manual/update/${this.modalID}`,
        formData,
        true,
      )

      if (response && response.status) {
        this.$root.$emit('bv::toggle::modal', this.id)
        this.getUserManualData()
      }
      this.loader = false
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/component-css/help.scss";
</style>
