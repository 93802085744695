<template>
  <div>
    <b-card
      no-body
      class="card-dashboard-section"
    >
      <b-card-header class="dashboard-header">
        <b-card-title>
          <div class="dashbord-nav help-header">
            <p class="task-timesheet m-0">
              User Manual
            </p>
          </div>
        </b-card-title>
      </b-card-header>
    </b-card>
    <div>
      <div class="add-role-table user-manual-table">
        <div class="table-responsive help-table d-block">
          <table
            v-if="UserManualData && UserManualData.length"
            class="m-0 table"
          >
            <thead>
              <tr>
                <th>Module Name</th>
                <th v-if="userInfo.role == 'SA'">
                  Upload Module Guide
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody
              v-for="item in UserManualData"
              :key="item.data.id"
              class="table-fix-height"
            >
              <tr
                v-show="userInfo.role == 'SA' || item.data.url || hasData(item.data)"
                :class="item.class"
                class="ml-5"
              >
                <td>{{ item.data.module_name }}</td>
                <td
                  v-if="userInfo.role == 'SA'"
                  class="upload-td"
                >
                  <div>
                    <div
                      v-show="item.data.children == 0 || item.data.parent_code !=null"
                      class="custom-upload"
                    >
                      <button
                        v-if="!item.data.url"
                        v-b-modal.UploadManual
                        v-b-tooltip.hover.top="'Upload File'"
                        class="Btn"
                        @click="modalID = item.data.id"
                      >
                        <svg
                          class="svgIcon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M13 20h-2V8l-5.5 5.5l-1.42-1.42L12 4.16l7.92 7.92l-1.42 1.42L13 8v12Z"
                          />
                        </svg>
                        <span class="icon2" />
                      </button>
                      <svg
                        v-else
                        v-b-tooltip.hover.top="'Update File'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        class="mt-75"
                        viewBox="0 0 16 16"
                        @click="getMediaData(item.data.id)"
                      >
                        <path
                          fill="#0d6efd"
                          d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm1.354 4.354l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708z"
                        />
                      </svg>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    v-if="item.data.url && (item.data.children == 0 || item.data.parent_code !=null)"
                    class="action-td d-flex"
                  >
                    <div
                      data-tooltip-id="View"
                      data-tooltip-content="View"
                      class="btnYes d-inline-block cursor-pointer"
                      @click="openFileInNewTab(item.data.url)"
                    >
                      <svg
                        v-b-tooltip.hover.top="'View'"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#B8B7BB"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        :class="userInfo.role == 'SA'? 'mr-1' : 'ml-2'"
                        class="editIcon"
                      >
                        <path
                          d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                        />
                        <circle
                          cx="12"
                          cy="12"
                          r="3"
                        />
                      </svg>
                    </div>
                    <div
                      v-show="userInfo.role == 'SA'"
                      v-b-tooltip.hover.top="'Delete'"
                      class="cursor-pointer"
                      @click="deleteConfirmation(item.data.id)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#ea5455"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash-2"
                      >
                        <polyline points="3 6 5 6 21 6" />
                        <path
                          d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                        />
                        <line
                          x1="10"
                          y1="11"
                          x2="10"
                          y2="17"
                        />
                        <line
                          x1="14"
                          y1="11"
                          x2="14"
                          y2="17"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      item.data.parent_code != null ||
                        (!item.data.url && item.data.children.length == 0)
                    "
                  >
                    <span class="ml-2">-</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <img
            v-if="userInfo.role != 'SA' && totalCount == 0"
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </div>
        <UploadManualModal
          v-if="modalID"
          :modal-i-d="modalID"
          :attachment="attachment"
          @clearAttachment="attachment = []"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UploadManualModal from './UploadManualModal.vue'
import { eventBus } from '../../../main'

export default {
  components: {
    UploadManualModal,
  },
  data() {
    return {
      loader: false,
      modalList: [],
      modalID: null,
      attachment: [],
      totalCount: 0,
    }
  },
  computed: {
    UserManualData() {
      const flatten = (arr, parentClass, depth = 0) => arr.reduce((flat, current) => {
        if (current.url) {
          this.totalCount = 1
        }
        const moduleClass = depth > 0 ? `sub-${parentClass}` : 'modual'
        return flat.concat([{ data: current, class: moduleClass }], flatten(current.children || [], moduleClass, depth + 1))
      }, [])

      return flatten(this.$store.state.app.originaluserManualList, 'modual')
    },
  },
  mounted() {
    eventBus.$on('reloadModalData', data => {
      if (data) {
        this.getModalNameList()
      }
    })
    this.getUserManualData()
  },
  destroyed() {
    eventBus.$off('reloadModalData')
  },
  methods: {
    async getMediaData(id) {
      this.loader = true
      this.modalID = id
      const response = await this.getHTTPGetResponse(
        `admin/master/user-manual/view/${id}`,
      )

      if (response && response.status) {
        const data = response.data.userManual
        this.attachment = data
        this.$root.$emit('bv::toggle::modal', 'UploadManual')
      }
      this.loader = false
    },
    hasData(item) {
      let result = false

      if (item.children.length) {
        item.children.forEach(element => {
          if (element.url) {
            result = true
          }
        })
      }
      return result
    },

    /**
     * confirmation to delete user
     */
    async deleteConfirmation(id) {
      const title = 'Are you sure you want to delete this User Manual?'
      const confirmation = await this.conformationAlert(true, title, 'Yes, delete it!', '<p>You won’t be able to revert this!</p>')
      if (confirmation.isConfirmed) {
        this.deleteUserManualFile(id)
      }
    },

    async deleteUserManualFile(id) {
      const response = await this.getHTTPDeleteResponse(
        `admin/master/user-manual/delete/${id}`,
        {},
        true,
      )
      if (response && response.status) {
        this.getUserManualData()
      }
    },
  },
}
</script>

<style lang="scss" >
@import "../../../assets/scss/component-css/help.scss";
</style>
